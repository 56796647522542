import { getAuth } from 'firebase/auth';
import { signOut } from 'next-auth/react';
import { useCallback, useState } from 'react';
import { useAfterAuthRedirect } from '~/app/auth/_hooks/use-after-auth-redirect';
import { useAfterRegisterRedirect } from '~/app/auth/_hooks/use-after-register-redirect';
import { useLoginPropertyUsers } from '~/common/hooks/use-auth/use-login-property-users';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useFirebaseAnalytics } from '~/common/hooks/use-firebase-analytics';
import { useSystemNotification } from '@app/_hooks/use-system-notification';
type LogoutFnOptions = Partial<{
  onComplete: () => void;
}>;

export const useLogout = (options: LogoutFnOptions = {}) => {
  const { session } = useTellerSession();
  const { setUserProperties } = useFirebaseAnalytics();
  const { notify } = useSystemNotification();
  const { removeLoginUserSet } = useLoginPropertyUsers();
  const { removeRedirectionPath } = useAfterAuthRedirect();
  const { removeRedirectionPath: removeRegisterRedirection } =
    useAfterRegisterRedirect();
  const [isLoginUserPropertyUpdated, setLoginUserPropertyUpdated] =
    useState(false);

  const logout = useCallback(async () => {
    if (!session || !session.tellerUser) return;

    try {
      removeRedirectionPath();
      removeRegisterRedirection();
      const auth = getAuth();
      await auth.signOut();
      await signOut({ redirect: true });

      setUserProperties({ is_login: false });
      if (session?.uid && !session.isAnonymous && !isLoginUserPropertyUpdated) {
        removeLoginUserSet(session.uid);
        setLoginUserPropertyUpdated(true);
      }
      options?.onComplete?.();

      notify('ログアウトしました', 'info');
    } catch {
      notify('ログアウトに失敗しました', 'error');
    }
  }, [
    isLoginUserPropertyUpdated,
    notify,
    options,
    removeLoginUserSet,
    removeRedirectionPath,
    removeRegisterRedirection,
    session,
    setUserProperties,
  ]);

  return {
    logout,
  };
};
