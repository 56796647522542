import type { ReadonlyURLSearchParams } from 'next/navigation';
import {
  createEpisodeDetailDeepLink,
  createSeriesDetailDeepLink,
  createTagDetailDeepLink,
  createUserDetailDeepLink,
} from '~/utils/deeplink';

type Params = {
  pathname: string;
  params?: Record<string, string | string[]> | null;
  searchParams?: ReadonlyURLSearchParams | null;
};

export const createDeepLink = ({
  pathname,
  params,
}: Params): string | undefined => {
  if (
    pathname.startsWith('/tag/') &&
    params &&
    typeof params?.['tagName'] === 'string'
  ) {
    // tag detail
    const tagName = decodeURI(params['tagName']);
    return createTagDetailDeepLink(tagName);
  } else if (
    pathname.startsWith('/se/') &&
    params &&
    typeof params?.['seriesId'] === 'string'
  ) {
    // series detail
    const seriesId = params['seriesId'];
    return createSeriesDetailDeepLink(seriesId);
  } else if (
    pathname.startsWith('/s/') &&
    params &&
    typeof params?.['storyId'] === 'string'
  ) {
    // episode detail
    const episodeId = params['storyId'];
    return createEpisodeDetailDeepLink(episodeId);
  } else if (
    pathname.startsWith('/user/') &&
    params &&
    typeof params?.['userId'] === 'string'
  ) {
    // episode detail
    const userId = params['userId'];
    return createUserDetailDeepLink(userId);
  } else if (pathname.startsWith('/notifications')) {
    // notifications
    return 'teller://notice';
  }

  // if not matched, return home
  return 'teller://';
};
