import qs from 'query-string';

export const getFirstQuery = (
  parsedUrlQueryValue: string | string[] | undefined | null
): string | undefined => {
  if (!parsedUrlQueryValue) return;

  return Array.isArray(parsedUrlQueryValue)
    ? parsedUrlQueryValue[0]
    : parsedUrlQueryValue;
};

export const createAppInstallUrl = ({
  deepLinkValue,
}: Partial<{
  deepLinkValue: string;
}>): string => {
  return qs.stringifyUrl({
    url: 'https://tellernovel.onelink.me/sLTh',
    query: {
      pid: 'web',
      af_dp: 'teller://',
      deep_link_value: deepLinkValue,
    },
  });
};

export const createOpenEpisodeDeeplink = (episodeId: string): string => {
  return `teller://read/${episodeId}`;
};

export const createOpenSeriesDeeplink = (seriesId: string): string => {
  return `teller://series/${seriesId}`;
};

export const createOpenTagsDeeplink = (tagName: string): string => {
  return `teller://tag/${tagName}`;
};

export const createOpenUserDeepLink = (userId: string): string => {
  return `teller://author/${userId}`;
};

export const storyCommentsDeeplink = (): string => {
  if (typeof window === 'undefined') {
    return 'teller://';
  }
  const story = /\/s\/(.*)/g.exec(window.location.pathname);
  if (story && story[1]) {
    return `teller://read/${story[1]}/comments`;
  }
  return 'teller://';
};

export const deeplink = (): string => {
  if (typeof window === 'undefined') {
    return 'teller://';
  }
  const story = /\/s\/(.*)/g.exec(window.location.pathname);
  if (story && story[1]) {
    return createOpenEpisodeDeeplink(story[1]);
  }
  const series = /\/se\/(.*)/g.exec(window.location.pathname);
  if (series && series[1]) {
    return createOpenSeriesDeeplink(series[1]);
  }
  const tag = /\/tag\/(.*)/g.exec(window.location.pathname);
  if (tag && tag[1]) {
    return createOpenTagsDeeplink(tag[1]);
  }

  return 'teller://';
};

export const stripTrailingSlash = (url: string): string => {
  if (url.slice(-1) === '/') {
    return url.slice(0, -1);
  }
  return url;
};
