'use client';
import { BottomNavigation } from '@app/_components/bottom-navigation';
import { SearchModalContainer } from '@app/_containers/search-modal-container';
import { VStack } from '@styled-system/jsx';
import { useLocalState } from './use-local-state';

export type ActiveOptionType =
  | 'home'
  | 'notifications'
  | 'bookshelf'
  | 'search';

export const BottomNavigationContainer = () => {
  const {
    notificationCount,
    handleStoryWriteClick,
    searchDisclosure,
    handleNotificationsClick,
    handleBookshelfClick,
    activeOption,
  } = useLocalState();
  return (
    <>
      <VStack
        position="sticky"
        bottom="0"
        left="0"
        right="0"
        w="100%"
        gap="0"
        zIndex="header"
        backfaceVisibility="hidden"
        hideFrom="lg"
      >
        <BottomNavigation
          newNotifications={notificationCount > 0}
          onClickSearch={searchDisclosure.onOpen}
          onClickWrite={handleStoryWriteClick}
          onClickNotifications={handleNotificationsClick}
          onClickBookshelf={handleBookshelfClick}
          activeOption={activeOption}
        />
      </VStack>
      <SearchModalContainer
        isOpen={searchDisclosure.isOpen}
        onClose={searchDisclosure.onClose}
      />
    </>
  );
};
