import { memo } from 'react';
import { Button } from '@app/_components/button';
import {
  HStack,
  HstackProps,
  Image,
  Paragraph,
  VStack,
} from '@styled-system/jsx';
import AppIcon from './assets/appicon.svg';

type Props = {
  onClick?: () => void;
} & HstackProps;

const _OpenInAppHeader = ({ onClick, ...hstackProps }: Props) => {
  return (
    <HStack
      w="100%"
      h="44px"
      px="16px"
      justifyContent="space-between"
      bg="surface_secondary"
      {...hstackProps}
    >
      <HStack gap="10px" userSelect="none">
        <Image src={AppIcon} alt="TellerNovel" w="30px" h="30px" />
        <VStack alignItems="flex-start" gap="1px">
          <Paragraph
            fontSize="size_13"
            fontWeight="bold"
            color="content_primary"
          >
            テラーノベル
          </Paragraph>
          <Paragraph fontSize="size_10" color="content_primary">
            アプリでサクサク楽しめる
          </Paragraph>
        </VStack>
      </HStack>

      <Button size="small" w="100px" h="30px" onClick={onClick}>
        アプリで開く
      </Button>
    </HStack>
  );
};

export const OpenInAppHeader = memo(_OpenInAppHeader);
