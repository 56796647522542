import { useRouter, usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useMemo } from 'react';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { useFirebaseAnalytics } from '~/common/hooks/use-firebase-analytics';
import { NOVEL_STUDIO_URL } from '~/utils/constants';
import { routes } from '~/utils/routes';
import { useDisclosure } from '@app/_hooks/use-disclosure';
import type { ActiveOptionType } from './bottom-navigation-container';
import { useGetNotificationCount } from './use-get-notification-count';

export const useLocalState = () => {
  const router = useRouter();
  const currentPath = usePathname();
  const searchParams = useSearchParams();
  const { logEvent } = useFirebaseAnalytics();
  const { session, status: sessionStatus } = useTellerSession();
  const isAnonymous = session?.isAnonymous;
  const isAuthenticated = sessionStatus === 'authenticated';

  // Search
  const searchDisclosure = useDisclosure();

  // handle on click write story
  const handleStoryWriteClick = useCallback(async () => {
    if (isAnonymous === true) {
      router.push(routes.login.url('/novel-studio'), undefined);
    } else {
      if (typeof window !== 'undefined') {
        logEvent('bottom_click_write_story');
        window.location.href = `${NOVEL_STUDIO_URL}?webUid=${session?.uid}`;
      }
    }
    searchDisclosure.onClose();
  }, [isAnonymous, router, searchDisclosure, logEvent, session?.uid]);

  const { notificationCount } = useGetNotificationCount();

  const handleNotificationsClick = useCallback(() => {
    router.push(
      isAnonymous
        ? routes.login.url('/notifications')
        : routes.notifications.url(),
      undefined
    );
    searchDisclosure.onClose();
  }, [isAnonymous, router, searchDisclosure]);

  const handleBookshelfClick = useCallback(() => {
    router.push(
      isAnonymous ? routes.login.url('/bookshelf') : routes.bookshelf.url(),
      undefined
    );
    searchDisclosure.onClose();
  }, [isAnonymous, router, searchDisclosure]);

  const activeOption = useMemo((): ActiveOptionType | undefined => {
    if (searchDisclosure.isOpen) return 'search';
    const fromParam = decodeURIComponent(searchParams?.get('from') || '');

    if (fromParam === '/notifications') return 'notifications';
    if (fromParam === '/bookshelf') return 'bookshelf';

    switch (currentPath) {
      case routes.home.url():
        return 'home';
      case routes.notifications.url():
        return 'notifications';
      case routes.bookshelf.url():
        return 'bookshelf';
      default:
        return undefined;
    }
  }, [currentPath, searchDisclosure.isOpen, searchParams]);

  return {
    isAuthenticated,
    notificationCount: notificationCount ?? 0,
    sessionStatus,
    handleStoryWriteClick,
    searchDisclosure,
    handleNotificationsClick,
    handleBookshelfClick,
    activeOption,
  };
};
