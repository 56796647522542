import qs from 'query-string';

/**
 * Open Tag Detail Page
 * @param tagName
 * @returns
 */
export const createTagDetailDeepLink = (tagName: string) => {
  return qs.stringifyUrl(
    {
      url: `teller://search/result`,
      query: {
        tags: [tagName],
      },
    },
    {
      skipNull: true,
    }
  );
};

/**
 * Open Series Detail Page
 * @param tagName
 * @returns
 */
export const createSeriesDetailDeepLink = (seriesId: string) => {
  return qs.stringifyUrl(
    {
      url: `teller://series/${seriesId}`,
    },
    {
      skipNull: true,
    }
  );
};

/**
 * Open Epsiode Detail Page
 * @param tagName
 * @returns
 */
export const createEpisodeDetailDeepLink = (episodeId: string) => {
  return qs.stringifyUrl(
    {
      url: `teller://read/${episodeId}`,
    },
    {
      skipNull: true,
    }
  );
};

/**
 * Open User Detail Page
 * @param tagName
 * @returns
 */
export const createUserDetailDeepLink = (userId: string) => {
  return qs.stringifyUrl(
    {
      url: `teller://user/${userId}`,
    },
    {
      skipNull: true,
    }
  );
};
