'use client';
import { Suspense } from 'react';
import { DialogMenu } from '~/app/_components/header-menu';
import { Header } from '@app/_components/header';
import { SearchModalContainer } from '@app/_containers/search-modal-container';
import { VStack } from '@styled-system/jsx';
import { OpenInAppHeaderContainer } from './open-in-app-header-container';
import { useLocalState } from './use-local-state';

export const HeaderContainer = () => {
  const {
    isSessionReady,
    isAnonymous,
    user,
    notificationCount,
    handleStoryWriteClick,
    handleOnClickMyStories,
    handleOnClickLogin,
    handleOnClickLogout,
    handleOnClickRegister,
    headerMenuDisclosure,
    searchDisclosure,
    appBarContainerRef,
    isMobile,
  } = useLocalState();

  return (
    <>
      <VStack
        position="sticky"
        top="0"
        left="0"
        right="0"
        w="100%"
        gap="0"
        zIndex="header"
        backfaceVisibility="hidden"
        overflow="hidden"
        ref={appBarContainerRef}
      >
        <Suspense fallback={null}>
          <OpenInAppHeaderContainer />
        </Suspense>
        <Header
          isSessionReady={isSessionReady}
          isAnonymous={isAnonymous}
          user={user}
          notificationCount={notificationCount}
          onClickSearch={searchDisclosure.onOpen}
          onClickWrite={handleStoryWriteClick}
          onClickMyStories={handleOnClickMyStories}
          onOpenHeaderMenu={headerMenuDisclosure.onOpen}
          onCloseHeaderMenu={headerMenuDisclosure.onClose}
          onClickLogin={handleOnClickLogin}
          onClickLogout={handleOnClickLogout}
          onClickRegister={handleOnClickRegister}
          isHeaderMenuOpen={headerMenuDisclosure.isOpen}
          isMobile={isMobile}
        />
      </VStack>
      {isMobile && (
        <DialogMenu
          isAnonymous={isAnonymous}
          isVip={user?.isVip}
          isOpen={headerMenuDisclosure.isOpen}
          onClose={headerMenuDisclosure.onClose}
          onClickMyStories={handleOnClickMyStories}
          onClickLogout={handleOnClickLogout}
        />
      )}

      <SearchModalContainer
        isOpen={searchDisclosure.isOpen}
        onClose={searchDisclosure.onClose}
      />
    </>
  );
};
