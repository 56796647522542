import { OpenInAppHeader } from '~/app/_components/open-in-app-header';
import { useLocalState } from './use-local-state';

export const OpenInAppHeaderContainer = () => {
  const { handleOnClickOpenInApp } = useLocalState();
  return (
    <OpenInAppHeader
      display={{
        base: 'none',
        lgDown: 'flex',
      }}
      onClick={handleOnClickOpenInApp}
    />
  );
};
