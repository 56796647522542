'use client';
import { useEffect } from 'react';
import { useSystemNotification } from '@app/_hooks/use-system-notification';

export const LOGIN_SUCCESS_KEY = 'login-success';

export const setLoginSuccess = () => {
  try {
    sessionStorage.setItem(LOGIN_SUCCESS_KEY, '1');
  } catch {
    // nop
  }
};

type Props = {
  children?: React.ReactNode;
};

export const AfterLoginNotificationProvider = ({ children }: Props) => {
  const { notify } = useSystemNotification();

  useEffect(() => {
    try {
      if (typeof sessionStorage === 'undefined') return;
      if (sessionStorage[LOGIN_SUCCESS_KEY] === '1') {
        notify('ログインしました', 'info');
        sessionStorage.removeItem(LOGIN_SUCCESS_KEY);
      }
    } catch {
      // nop
    }
  }, [notify]);

  return <>{children}</>;
};
