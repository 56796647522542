import { useCallback } from 'react';
import { useDeepLinkFromPath } from '~/app/_hooks/use-deep-link-from-path/use-deep-link-from-path';
import { createAppInstallUrl } from '~/utils/url';

export const useLocalState = () => {
  // OpenInApp
  const { deepLinkUrl } = useDeepLinkFromPath();
  const handleOnClickOpenInApp = useCallback(() => {
    if (typeof window !== 'undefined' && deepLinkUrl) {
      window.location.href = createAppInstallUrl({
        deepLinkValue: deepLinkUrl,
      });
    }
  }, [deepLinkUrl]);

  return {
    handleOnClickOpenInApp,
  };
};
