import { useSearchParams, useParams, usePathname } from 'next/navigation';
import { useMemo } from 'react';
import { createDeepLink } from './create-deep-link';

// create deep link url from page path
export const useDeepLinkFromPath = () => {
  const pathname = usePathname();
  const params = useParams();
  const searchParams = useSearchParams();

  const deepLinkUrl = useMemo(() => {
    if (!pathname) return;

    return createDeepLink({
      pathname,
      params,
      searchParams,
    });
  }, [params, pathname, searchParams]);

  return {
    deepLinkUrl,
  };
};
