import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';
import { setLoginSuccess } from '~/app/_providers/after-login-notification-provider';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';

const LOCAL_STORAGE_KEY = 'after-auth-redirect';

const REDIRECT_ALLOW_PATHS: RegExp[] = [
  /^\/user\//,
  /^\/se\//,
  /^\/s\//,
  /^\/tag\//,
  /^\/chat-studio/,
  /^\/novel-studio/,
  /^\/notifications/,
  /^\/bookshelf/,
];

const isValidRedirectTarget = (param: string): boolean => {
  return REDIRECT_ALLOW_PATHS.some((path) => path.test(param));
};

export const useAfterAuthRedirect = () => {
  const { session } = useTellerSession();

  const [redirectionPath, storeRedirectionPath, removeRedirectionPath] =
    useLocalStorage<string | undefined>(LOCAL_STORAGE_KEY, undefined);

  // Check if we should redirect to a specific page after login
  // and store the path in session
  const onBeforeLogin = useCallback(() => {
    if (typeof window === 'undefined' || !session) return;

    const urlParams = new URLSearchParams(window.location.search);
    const fromParam = decodeURIComponent(urlParams.get('from') || '');
    if (!fromParam) {
      removeRedirectionPath();
      return;
    }

    if (isValidRedirectTarget(fromParam)) {
      storeRedirectionPath(fromParam);
    }
  }, [removeRedirectionPath, session, storeRedirectionPath]);

  // After login, check if we should redirect to a specific page stored in session
  // and redirect to that page, or to home with a login done message if not
  const onAfterLogin = useCallback(() => {
    if (typeof window === 'undefined') return;

    removeRedirectionPath();

    setLoginSuccess();
  }, [removeRedirectionPath]);

  return {
    redirectionPath,
    removeRedirectionPath,
    onBeforeLogin,
    onAfterLogin,
  };
};
