'use client';
import useWebAnimations from '@wellyshen/use-web-animations';
import { useCallback, useEffect, useState } from 'react';
import { useMedia, usePrevious, useWindowScroll } from 'react-use';
import { Breakpoints } from '~/utils/styles/enums';

// 一定のしきい値以上の移動量があった場合にのみヘッダーを表示/非表示する
const SCROLL_ANIM_THRESHOLD = 10;

export const useQuickReturnHeader = () => {
  const { y } = useWindowScroll();
  const prevY = usePrevious(y);
  const isScreenLarge = useMedia(`(min-width: ${Breakpoints.lg})`, false);
  const [isHide, seIsHide] = useState(false);
  const { ref, animate } = useWebAnimations<HTMLDivElement>();

  const showHeader = useCallback(() => {
    if (!ref.current || !isHide) return;
    seIsHide(false);

    // show
    animate({
      keyframes: [
        {
          transform: 'translateY(0)',
        },
      ],
      animationOptions: {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out',
      },
    });
  }, [animate, ref, isHide]);

  const hideHeader = useCallback(() => {
    if (!ref.current || isHide) return;
    seIsHide(true);

    // hide
    animate({
      keyframes: [
        {
          transform: `translateY(-${ref.current.offsetHeight}px)`,
        },
      ],
      animationOptions: {
        duration: 300,
        fill: 'forwards',
        easing: 'ease-out',
      },
    });
  }, [animate, ref, isHide]);

  useEffect(() => {
    if (!ref.current) return;
    if (isScreenLarge || prevY === undefined) {
      showHeader();
      return;
    }

    const diffY = y - prevY;

    if (diffY > SCROLL_ANIM_THRESHOLD && y > ref.current.offsetHeight) {
      // hide
      hideHeader();
    } else if (diffY < -SCROLL_ANIM_THRESHOLD) {
      // show
      showHeader();
    }
  }, [animate, hideHeader, isScreenLarge, prevY, ref, showHeader, y]);

  return {
    appBarContainerRef: ref,
  };
};
