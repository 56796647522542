import { usePathname } from 'next/navigation';
import useSWR from 'swr';
import { getNotificationSince } from '~/app/_repositories/me-service';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { NotificationCategory } from '~/models/query-types';
import { OS } from '~/models/types';
import { fetchLastNotificationCheckedAt } from '~/usecases/me-use-case';

const fetchNotificationCountSince = (userId: string) => {
  const lastCheckedAt = fetchLastNotificationCheckedAt(userId);
  return getNotificationSince({
    os: OS.OS_UNSPECIFIED,
    category: NotificationCategory.NOTIFICATION_CATEGORY_UNSPECIFIED,
    since: lastCheckedAt.toISOString(),
  });
};

export const useGetNotificationCount = () => {
  const { session } = useTellerSession();
  const pathname = usePathname();
  const { data: notificationCount } = useSWR(
    session?.uid && session?.tellerUser?.id && !session.isAnonymous
      ? ['/api/user_notifications_count', session?.tellerUser.id, pathname]
      : null,
    ([_, userId, __]) => fetchNotificationCountSince(userId)
  );

  return {
    notificationCount,
  };
};
