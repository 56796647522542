import { useCallback } from 'react';
import toast, { type ToastOptions } from 'react-hot-toast';
import { Color } from '~/utils/styles/enums';

export const SnackbarVariant = {
  INFO: 'info',
  ERROR: 'error',
} as const;
export type SnackbarVariant =
  typeof SnackbarVariant[keyof typeof SnackbarVariant];

export const useSystemNotification = () => {
  const notify = useCallback(
    (
      message: string | JSX.Element | null,
      variant?: SnackbarVariant,
      options?: ToastOptions
    ) => {
      toast(message, {
        position: 'bottom-center',
        style: {
          padding: '8px 16px',
          color: Color.PRIMARY,
          textAlign: 'center',
          backgroundColor: (() => {
            switch (variant) {
              case 'info':
                return Color.TINT;
              case 'error':
                return Color.RED;
              default:
                return Color.GREY;
            }
          })(),
          borderRadius: '4px',
        },
        ...options,
      });
    },
    []
  );

  return {
    notify,
  };
};
