'use client';
import { memo } from 'react';
import { routes } from '~/utils/routes';
import { BottomNavigationIcon } from '@app/_components/bottom-navigation-icon';
import { BottomNavigationWriteButton } from '@app/_components/bottom-navigation-write-button';
import type { ActiveOptionType } from '@app/_containers/bottom-navigation-container';
import { HStack, Image, Box, Center } from '@styled-system/jsx';
import bookshelfActiveIcon from './assets/bookshelf-active-icon.svg';
import bookshelfIcon from './assets/bookshelf-icon.svg';
import homeActiveIcon from './assets/home-active-icon.svg';
import homeIcon from './assets/home-icon.svg';
import notificationsActiveIcon from './assets/notifications-active-icon.svg';
import notificationsIcon from './assets/notifications-icon.svg';
import searchActiveIcon from './assets/search-active-icon.svg';
import searchIcon from './assets/search-icon.svg';

type Props = {
  newNotifications?: boolean;
  onClickSearch: () => void;
  onClickWrite: () => void;
  onClickNotifications: () => void;
  onClickBookshelf: () => void;
  activeOption?: ActiveOptionType;
};

const _BottomNavigation = ({
  newNotifications,
  onClickSearch,
  onClickWrite,
  onClickNotifications,
  onClickBookshelf,
  activeOption,
}: Props) => {
  return (
    <HStack
      justifyContent="space-around"
      width="100%"
      backgroundColor="surface_primary"
      boxShadow="0px -2px 4px 0px rgba(0, 0, 0, 0.04)"
      padding="5px"
      height="54px"
      zIndex="bottom_navigation"
    >
      <a href={routes.home.url()}>
        <BottomNavigationIcon
          text="ホーム"
          activeIcon={<Image src={homeActiveIcon} alt="本棚" />}
          icon={<Image src={homeIcon} alt="本棚" />}
          active={activeOption === 'home'}
        />
      </a>
      <BottomNavigationIcon
        text="検索"
        onClick={onClickSearch}
        activeIcon={<Image src={searchActiveIcon} alt="本棚" />}
        icon={<Image src={searchIcon} alt="本棚" />}
        active={activeOption === 'search'}
      />
      <BottomNavigationWriteButton onClick={onClickWrite} />
      <Box position="relative">
        <BottomNavigationIcon
          text="通知"
          onClick={onClickNotifications}
          activeIcon={<Image src={notificationsActiveIcon} alt="本棚" />}
          icon={<Image src={notificationsIcon} alt="本棚" />}
          active={activeOption === 'notifications'}
        />
        {newNotifications && (
          <Center
            bgColor="red"
            color="white"
            fontSize="size_10"
            fontWeight="bold"
            borderRadius="50%"
            h="18px"
            w="18px"
            position="absolute"
            top="-5px"
            right="10px"
            textAlign="center"
            animation="fadein 100ms ease-out"
          >
            {' '}
          </Center>
        )}
      </Box>
      <BottomNavigationIcon
        text="本棚"
        onClick={onClickBookshelf}
        activeIcon={<Image src={bookshelfActiveIcon} alt="本棚" />}
        icon={<Image src={bookshelfIcon} alt="本棚" />}
        active={activeOption === 'bookshelf'}
      />
    </HStack>
  );
};

export const BottomNavigation = memo(_BottomNavigation);
