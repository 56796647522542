import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';
import { useTellerSession } from '~/common/hooks/use-auth/use-teller-session';
import { routes } from '~/utils/routes';

const LOCAL_STORAGE_KEY = 'after-register-redirect';

const REDIRECT_ALLOW_PATHS: RegExp[] = [
  /^\/profile\/plan_selection/,
  /^\/registration_finished/,
];

const isValidRedirectTarget = (param: string): boolean => {
  return REDIRECT_ALLOW_PATHS.some((path) => path.test(param));
};

export const useAfterRegisterRedirect = () => {
  const { session } = useTellerSession();

  const [redirectionPath, storeRedirectionPath, removeRedirectionPath] =
    useLocalStorage<string | undefined>(LOCAL_STORAGE_KEY, undefined);

  // Check if we should redirect to a specific page after register
  // and store the path in local storage
  const onBeforeRegister = useCallback(() => {
    if (typeof window === 'undefined' || !session) return;

    const urlParams = new URLSearchParams(window.location.search);
    const paymentParam = decodeURIComponent(urlParams.get('p') || '');
    const fromParam = decodeURIComponent(urlParams.get('from') || '');
    const isDirectlyToPayment = paymentParam === '1';
    const isDirectlyToPerkPayment = fromParam === 'perklp';

    const redirectPath =
      isDirectlyToPayment || isDirectlyToPerkPayment
        ? routes.profile.planSelection.url(
            isDirectlyToPerkPayment ? 'perklp' : 'lp'
          )
        : routes.registration_finished.url();

    if (isValidRedirectTarget(redirectPath)) {
      storeRedirectionPath(redirectPath);
    }
  }, [session, storeRedirectionPath]);

  return {
    redirectionPath,
    onBeforeRegister,
    removeRedirectionPath,
  };
};
