'use client';
import { memo } from 'react';
import { VStack, Paragraph } from '@styled-system/jsx';

type Props = {
  active?: boolean;
  text: string;
  onClick?: () => void;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
};

const _BottomNavigationIcon = ({
  active,
  text,
  onClick,
  icon,
  activeIcon,
}: Props) => {
  return (
    <VStack
      alignItems="center"
      width="60px"
      justifyContent="center"
      onClick={onClick}
    >
      <div>
        {active && activeIcon ? activeIcon : icon}
        <Paragraph
          width="30px"
          marginTop="3px"
          marginLeft="-3px"
          textAlign="center"
          fontSize="size_10"
          fontWeight="700"
          color={`${active ? 'content_primary' : 'content_tertiary'}`}
        >
          {text}
        </Paragraph>
      </div>
    </VStack>
  );
};

export const BottomNavigationIcon = memo(_BottomNavigationIcon);
