import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

export const LOGIN_PROPERTY_KEY = 'login_property_users';

export const useLoginPropertyUsers = () => {
  // Contains list of user ids
  const [loginPropertiesSet, setLoginPropertySet] = useLocalStorage<string[]>(
    LOGIN_PROPERTY_KEY,
    []
  );

  const isLoginUserSet = useCallback(
    (uid: string): boolean | undefined => loginPropertiesSet?.includes(uid),
    [loginPropertiesSet]
  );

  const addLoginUserSet = useCallback(
    (uid: string): void => {
      const settedUsers = loginPropertiesSet;
      settedUsers?.push(uid);
      setLoginPropertySet(settedUsers);
    },
    [loginPropertiesSet, setLoginPropertySet]
  );

  const removeLoginUserSet = useCallback(
    (uid: string): void => {
      const settedUsers = loginPropertiesSet?.filter((id) => id !== uid);
      setLoginPropertySet(settedUsers);
    },
    [loginPropertiesSet, setLoginPropertySet]
  );

  return {
    isLoginUserSet,
    addLoginUserSet,
    removeLoginUserSet,
  };
};
