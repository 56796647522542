import { Box } from '@styled-system/jsx';
import writeIcon from './assets/write-icon.svg';

type Props = {
  onClick: () => void;
};

export const BottomNavigationWriteButton = ({ onClick }: Props) => {
  return (
    <Box onClick={onClick}>
      <img
        src={writeIcon}
        alt="ストーリーを書く"
        onClick={onClick}
        width="42px"
        height="42px"
      />
    </Box>
  );
};
